<template>
    <div>
        <div class="header-page">
            <div class="header-font">
                Branches
            </div>
            <div>
                <v-btn color="primary create-btn" @click="createBranch">Create</v-btn>
            </div>
        </div>
        <v-data-table
            :headers="headers"
            :items="branchList"
            class="elevation-1"
        >
            <template v-slot:[`item.actions`]="{ item }">
                <div class="text-truncate">
                <v-icon
                    small
                    class="mr-2"
                    @click="editBranch(item)"
                >
                    mdi-pencil
                </v-icon>
                <v-icon
                    small
                    @click="deleteModal(item)"
                >
                    mdi-delete
                </v-icon>
            </div>
            </template>
        </v-data-table>
        <branch-form
            ref="branchForm"
            :branchId="branchId"
            :mode="mode"
            @close-modal="resetValueForm"
            @create-success="getBranchList"
            @edit-success="getBranchList"
        ></branch-form>
        <delete-modal
            ref="deleteModal"
            @delete-success="deleteSuccess"
            :deleteForm="deleteForm"
        ></delete-modal>
    </div>
</template>

<script>
import BranchForm from '../components/BranchForm'
import DeleteModal from '../components/DeleteModal'
export default {
    name: 'Branch',
    components: {
        BranchForm,
        DeleteModal
    },
    data () {
        return {
            branchList: [],
            headers: [
                { text: 'Name', value: 'name' },
                { text: 'Phone', value: 'phone' },
                { text: '', value: 'actions' }
            ],
            branchId: null,
            deleteForm: {
                header: 'Delete branch',
                title: 'You want to delete branch ',
                url: ''
            },
            mode: ''
        }
    },

    methods: {
        async getBranchList () {
            const res = await this.axios.get('branches')
            if (res && res.data && res.data.status === 'ok') {
                this.branchList = res.data.data
            }
        },

        createBranch () {
            this.branchId = null
            this.mode = 'create'
            this.openModal()
        },

        editBranch (item) {
            this.branchId = item.id
            this.mode = 'edit'
            this.openModal()
        },

        openModal () {
            this.$refs.branchForm.dialog = true
        },

        deleteModal (item) {
            this.deleteForm.title = `You want to delete branch ${item.name} ?`
            this.deleteForm.url = `branches/${item.id}`
            this.$refs.deleteModal.dialog = true
        },

        async deleteSuccess () {
            this.$notify({
                title: 'Congratulations',
                text: 'Delete branch success',
                type: 'success'
            })
            await this.getBranchList()
        },

        resetValueForm () {
            this.mode = ''
            this.branchId = null
        }
    },

    async mounted () {
        await this.getBranchList()
    }
}
</script>

<style lang="scss" scoped>

</style>
